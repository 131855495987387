<template>
  <div class="container page">
    <van-nav-bar title="开户中心" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <template #right>
        <span class="nav-right" @click="doRegister()">创建</span>
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-field v-model="code" clearable input-align="center" disabled class="input" placeholder="邀请码" />
      <van-field v-model="username" clearable input-align="center" class="input" placeholder="请输入账户" />
      <van-field v-model="password" :type="passwordType" input-align="center" class="input" placeholder="请输入密码">
        <template slot="right-icon">
          <van-icon :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'" @click="switchPasswordType" />
        </template>
      </van-field>
      <van-field v-model="password2" :type="passwordType2" input-align="center" class="input" placeholder="请确认密码">
        <template slot="right-icon">
          <van-icon :name="passwordType2 === 'password' ? 'closed-eye' : 'eye-o'" @click="switchPasswordType2" />
        </template>
      </van-field>
      <van-field v-model="usercode" clearable input-align="center" class="input" placeholder="请输入验证码">
        <template slot="right-icon">
          <van-image class="vcode-img" :src="vcode.img" @click="getVCode()" />
        </template>
      </van-field>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      usercode: '',
      vcode: { code: 1234 },
      checked: true,
      username: '',
      code: '123456',
      password2: '',
      passwordType2: 'password',
      password: this.inputValue,
      passwordType: 'password'
    }
  },
  methods: {
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data
          this.code = this.userInfo.code
          console.log(this.userInfo)
        } else if (res.code === 401) {
          this.$toast(res.msg)
        }
      })
    },
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    getVCode() {
      this.$http({
        method: 'get',
        url: 'vcode'
      })
        .then((res) => {
          console.log(res)
          this.vcode = { ovcode: res.data.code, img: res.data.img }
          console.log(this.vcode.img)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    switchPasswordType2() {
      this.passwordType2 = this.passwordType2 === 'password' ? 'text' : 'password'
    },
    back() {
      return window.history.back()
    },
    doRegister() {
      if (this.username === '' || this.username === null || this.username === undefined) {
        this.$toast.fail('请输入用户名！')
        return false
      }
      if (this.password === '' || this.password === null || this.password === undefined) {
        this.$toast.fail('请输入密码！')
        return false
      }
      if (this.password2 === '' || this.password2 === null || this.password2 === undefined) {
        this.$toast.fail('请输入确认密码！')
        return false
      }
      if (this.password2 != this.password) {
        this.$toast.fail('2次输入的密码不一致！')
        return false
      }
      if (this.code === '' || this.code === null || this.code === undefined) {
        this.$toast.fail('请输入邀请码！')
        return false
      }
      if (this.usercode === '' || this.usercode === null || this.usercode === undefined) {
        this.$toast.fail('请输入验证码！')
        return false
      }

      if (this.usercode != this.vcode.ovcode) {
        this.$toast.fail('验证码错误！')
        return false
      }

      this.$http({
        method: 'post',
        data: {
          username: this.username,
          password: this.password,
          vcode: this.usercode,
          code: this.code
        },
        url: 'member_register'
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg)
          //localStorage.setItem('token', res.data)
          //this.$router.push('Mine')
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  },
  created() {
    this.getVCode()
    this.getUserInfo()
  }
}
</script>

<style lang="less" scoped>
@import '../../assets/css/base.css';
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
</style>
