<template>
  <div id="app">
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Footer from './common/Footer'
export default {
  name: 'app',
  components: {
    Footer
  },
  data() {
    return {
      status: 0,
      count: 0
    }
  },
  methods: {
    getBaseInfo() {
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then((res) => {
        if (!localStorage.getItem('token')) {
          this.$router.push({ path: '/Login' })
        }
        this.$store.commit('setBaseInfoValue', res.data)
      })
    }
    // getMessagecount() {
    //   this.$http({
    //     method: 'get',
    //     url: 'message_count'
    //   }).then((res) => {
    //     this.count = res.data
    //     console.log(this.count)
    //     if (this.count > 0) {
    //       this.$dialog({ title: '提示', message: '有新的站内私信，点击去查看' }).then(() => {
    //         this.$router.push({ path: '/Message' })
    //       })
    //     }
    //   })
    // }
  },
  created() {
    this.getBaseInfo()
  },
  mounted() {
    console.log('destroyed')
  },
  destroyed() {}
}
</script>

<style>
body .van-toast {
  font-size: 38px;
  padding: 30px;
  line-height: 50px;
  width: 230px;
}
body .van-toast .van-toast__icon {
  font-size: 50px;
}
*,
:after,
:before {
  box-sizing: border-box;
}
</style>
